import request from 'api/requestUtils'
import { templates } from '@luxuryescapes/lib-uri-templates'
import { Tour, Response, Request } from '@luxuryescapes/contract-svc-tour'
import qs from 'qs'
import { tourV2SnapshotMap } from './mappers/TourV2/tourV2SnapshotMapper'

interface GetTourV2OfferIdParams {
  reservationId: string
}

export function getSnapshotDetailsForTourV2Offer({ reservationId }: GetTourV2OfferIdParams) {
  const uri = templates.tour.tour_reservation_snapshot.expand({ reservation_id: reservationId })

  return request.get<App.ApiResponse<Tour.ReservationSnapshot>>(uri, { credentials: 'include' })
    .then(data => tourV2SnapshotMap(data.result))
}

export function sendPrivateRequestForTourV2(requestPrivateTourData: Tours.RequestPrivateTour) {
  return request.post('/api/v2/tours/notify/request-private-tour', requestPrivateTourData)
}

export async function sendWaitingListRequestForTourV2(requestWaitingListTourData: Tours.WaitingListTourRequest) {
  return request.post<App.ApiResponse, Tours.WaitingListTourRequest>('/api/v2/tours/notify/waiting-list-sold-out-tour', requestWaitingListTourData)
}

export function getDepartureInstructions(departureId: string) {
  return request.get<App.ApiResponse<Response.MyEscapesResponse>>(`/api/v2/tours/myEscapes/${departureId}`, { credentials: 'include' }).then(data => data.result)
}

export function downloadReservationItineraryPDFUrl(orderId: string, reservationId: string): Promise<Blob> {
  return request.get(`/api/v2/tours/reservations/${reservationId}/order/${orderId}/itinerary-pdf`, { credentials: 'include' })
}

export function getTourOperators(): Promise<Record<string, Tours.TourOperator>> {
  return request.get<App.ApiResponse<Response.GetOperatorsResponse>>('/api/v2/tours/operators')
    .then(data => {
      const operators = {} as Record<string, Tours.TourOperator>
      Object.entries(data.result).forEach(([key, operator]) => {
        operators[key] = {
          name: operator.name,
          imageId: operator.imageId,
          code: operator.code,
        }
      })
      return operators
    })
}

function mapTailorMadeTour(external: Response.TailorTourFee): Tours.TailorMadeTour {
  return {
    fee: external.fee,
  }
}
interface TailorMadeTourGetParams {
  region: string
}
export async function requestGetTailorMadeTour(
  {
    region,
  }: TailorMadeTourGetParams,
): Promise<Tours.TailorMadeTour> {
  const url = `/api/v2/tours/tailor-made-tour-request-fee?${qs.stringify({ region })}`

  return request.get<App.ApiResponse<Response.TailorTourFee>>(url)
    .then(response => {
      return mapTailorMadeTour(response.result)
    })
}

interface TailorMadeTourRequestPostParams {
  region: string
}
export async function requestPostTailorMadeTourRequest(
  tailorMadeTourRequest: Tours.TailorMadeTourRequest,
  {
    region,
  }: TailorMadeTourRequestPostParams,
): Promise<string> {
  const url = `/api/v2/tours/tailor-made-tour-request?${qs.stringify({ region })}`

  const payload: Request.PostTailorMadeTourRequestPayload = {
    tourId: tailorMadeTourRequest.tourOfferId,
    tourOptionId: tailorMadeTourRequest.tourOptionId,
    request: {
      destinations: tailorMadeTourRequest.destinations,
      travelDates: tailorMadeTourRequest.travelDates,
      travelLength: {
        from: tailorMadeTourRequest.travelLength.from,
        to: tailorMadeTourRequest.travelLength.to,
      },
      budget: {
        from: tailorMadeTourRequest.budget.from,
        to: tailorMadeTourRequest.budget.to,
      },
      travellers: {
        adults: tailorMadeTourRequest.travellers.adults,
        children: tailorMadeTourRequest.travellers.children,
        infants: tailorMadeTourRequest.travellers.infants,
      },
      message: tailorMadeTourRequest.message,
      contactFirstName: tailorMadeTourRequest.contactFirstName,
      contactLastName: tailorMadeTourRequest.contactLastName,
      contactEmail: tailorMadeTourRequest.contactEmail,
      contactPhone: {
        number: tailorMadeTourRequest.contactPhone.number,
        prefix: tailorMadeTourRequest.contactPhone.prefix,
      },
      contactCountry: tailorMadeTourRequest.contactCountry,
      preferredCallbackTime: {
        from: tailorMadeTourRequest.preferredCallbackTime.from,
        to: tailorMadeTourRequest.preferredCallbackTime.to,
      },
    },
  }

  return request.post<App.ApiResponse<Response.TailorMadeTourRequestResponse>, Request.PostTailorMadeTourRequestPayload>(url, payload, { credentials: 'include' })
    .then(response => {
      return response.result.requestId
    })
}

function mapTailorMadeTourOrder(external: Response.GetTailorMadeTourRequestBookingResponse): Tours.TailorMadeTourOrder {
  const mapped: Tours.TailorMadeTourOrder = {
    status: external.status,
    destinations: external.request.destinations,
    travelDates: external.request.travelDates,
    travelLength: {
      from: external.request.travelLength.from,
      to: external.request.travelLength.to,
    },
    budget: {
      from: external.request.budget.from,
      to: external.request.budget.to,
    },
    travellers: {
      adults: external.request.travellers.adults,
      children: external.request.travellers.children,
    },
    message: external.request.message ?? '',
    contactFirstName: external.request.contactFirstName,
    contactLastName: external.request.contactLastName,
    contactEmail: external.request.contactEmail,
    contactPhone: {
      prefix: external.request.contactPhone.prefix,
      number: external.request.contactPhone.number,
    },
    contactCountry: external.request.contactCountry,
    preferredCallbackTime: {
      from: external.request.preferredCallbackTime.from,
      to: external.request.preferredCallbackTime.to,
    },
  }

  return mapped
}
interface TailorMadeTourOrderGetParams {
  region: string
}
export async function requestGetTailorMadeTourOrder(
  requestId: string,
  { region }: TailorMadeTourOrderGetParams,
) {
  const url = `/api/v2/tours/tailor-made-tour-request/${requestId}?${qs.stringify({ region })}`

  return request.get<App.ApiResponse<Response.GetTailorMadeTourRequestBookingResponse>>(url, { credentials: 'include' })
    .then(response => {
      return mapTailorMadeTourOrder(response.result)
    })
    .catch((error) => {
      if (error.status === 404) return 'Could not find the requested tailor-made tour'
      return 'Something went wrong while fetching the tailor-made tour'
    })
}
