import { API_CALL, CALENDAR_UPDATE_PRICING_DATA, CLEAR_BOOKING_CALENDAR, UPDATE_CALENDAR_FLIGHT_PROVIDER } from './actionConstants'

import { FETCH_CALENDARS, FETCH_CALENDARS_BY_OCCUPANCY, FETCH_TOUR_DATES } from './apiActionConstants'
import { getCalendars, getCalendarsByOccupancy, CalendarParams } from 'api/calendarV2'
import generateOccupancyStringByRoom from 'lib/offer/generateOccupancyStringByRoom'
import { tourEnquiry } from 'api/reservation'
import getObjectKey from 'lib/object/getObjectKey'

export function clearBookingCalendar() {
  return {
    type: CLEAR_BOOKING_CALENDAR,
  }
}

export function updateFlightProvider(provider) {
  return {
    type: UPDATE_CALENDAR_FLIGHT_PROVIDER,
    data: provider,
  }
}

interface FetchCalendarOptions {
  occupants?: Array<App.Occupants>;
  flightOrigin?: string;
  minDate?: string;
  packages?: Array<App.Package>;
  packageKeys?: Array<string>;
  bypassCache?: boolean;
  regionCode?: string;
}

export function fetchCalendars(offerId: string, options: FetchCalendarOptions = {}) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const offer = state.offer.offers[offerId] ?? state.offer.offerSummaries[offerId]

    const packages = options.packages ?? offer?.packages ?? []
    const packagesToFetch = packages.filter(pkg =>
      // remove any keys we're already loading or have loaded
      !state.calendar.calendarsByPackageKey[pkg.uniqueKey] &&
      !state.calendar.calendarsLoading[pkg.uniqueKey],
    )

    if (
      !options.bypassCache &&
      // offer is already loading (without knowledge of it's package keys)
      ((!options.packages && state.calendar.calendarsLoading[offerId]) ||
      // we know what package keys are loading and there are none
      (packages.length > 0 && packagesToFetch.length === 0))
    ) {
      return
    }

    dispatch({
      api: FETCH_CALENDARS,
      type: API_CALL,
      offerId: offer.id,
      packageKeys: packagesToFetch.map(pkg => pkg.uniqueKey),
      request: () => getCalendars(
        offer,
        options.regionCode || state.geo.currentRegionCode,
        {
          occupants: options.occupants ?? [],
          flightOrigin: options.flightOrigin,
          minDate: options.minDate,
          packages: packagesToFetch,
          enquiryType: state.auth.account.isSpoofed ? 'admin' : 'customer',
        },
      ),
    })
  }
}

type CalendarRequestItem = {
  occupancyKey: string;
  params: CalendarParams;
}

export function fetchCalendarsByOccupancy(offerId: string, options: FetchCalendarOptions) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const offer = state.offer.offers[offerId] ?? state.offer.offerSummaries[offerId]

    const packages = options?.packages ?? []
    const requests = (options?.occupants ?? []).reduce<Array<CalendarRequestItem>>((acc, occ) => {
      const occupancyKey = generateOccupancyStringByRoom(occ)

      const pkgLoadingMap = state.calendar.calendarsByOccupancyLoading[occupancyKey] ?? {}
      const pkgLoadedMap = state.calendar.calendarsByOccupancy[occupancyKey] ?? {}

      /**
        * Fetch packages if they are either:
        * - not loading
        * - not loaded
        */
      const packagesToFetch = packages.filter(pkg => !pkgLoadingMap[pkg.uniqueKey] && !pkgLoadedMap[pkg.uniqueKey])
      if (packagesToFetch.length > 0) {
        const params: CalendarParams = {
          occupants: [occ],
          packages: packagesToFetch,
          flightOrigin: options.flightOrigin,
          minDate: options.minDate,
          enquiryType: state.auth.account.isSpoofed ? 'admin' : 'customer',
        }
        acc.push({ occupancyKey, params })
      }
      return acc
    }, [])

    if (!options.bypassCache && requests.length === 0) {
      return
    }

    requests.forEach((request) => {
      const action = {
        api: FETCH_CALENDARS_BY_OCCUPANCY,
        type: API_CALL,
        occupancyKey: request.occupancyKey,
        pkgKeys: request.params.packages.map(pkg => pkg.uniqueKey),
        request: () => getCalendarsByOccupancy(
          offer,
          options.regionCode || state.geo.currentRegionCode,
          request.params,
        ),
      }
      dispatch(action)
    })
  }
}

export interface PricingData {
  hotelPrice: number;
  hotelValue: number;
  taxesAndFees: number;
  propertyFees: number;
  surcharge: number;
}
export function updateCalendarPricingData(
  offerId: string,
  uniqueKey: string,
  checkIn: string,
  occupancy: string | undefined,
  data: Partial<PricingData>,
) {
  return {
    type: CALENDAR_UPDATE_PRICING_DATA,
    offerId,
    uniqueKey,
    checkIn,
    occupancy,
    data,
  }
}

export function getTourDates(
  tourId: string,
  tourOptionId: string,
  duration: number,
  adults: number,
) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const key = getObjectKey({ tourId, tourOptionId, duration, adults })

    if (
      state.calendar.calendarsLoading[key] ||
      state.calendar.calendarErrors[key] ||
      state.calendar.tourDates[key]
    ) {
      // already done, no need to try again
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_TOUR_DATES,
      request: () => tourEnquiry(tourId, tourOptionId, duration, adults),
      key,
    })
  }
}
